import imgBlocco1 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___1.png';
import imgBlocco2 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___2.png';
import imgBlocco3 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___3-2.png';
import imgBlocco4 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___4.png';
import imgBlocco5 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___5.png';
import imgBlocco6 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___6.png';

import img1 from '../../src/images/data/broadband-1.png';
import img2 from '../../src/images/data/broadband-2.png';
import img3 from '../../src/images/data/broadband-3.png';
import img4 from '../../src/images/data/bb-graph-ire2.jpg';

import logo from '../../src/images/data/logo-bb.png';

const q = {
    data : {
        wp: {
            broadband: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                options : [
                    {
                        id: 'q-apps',
                        text : '<h1>Our best ever WiFi</h1>',
                        blocks : [
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco1,
                                title : 'Speeds up to 1Gb',
                                text : 'Do more online, on more devices',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco2,
                                title : 'Always stay connected',
                                text : '99.9% reliable broadband',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco3,
                                title : 'Totally unlimited',
                                text : 'Surf, stream and game with ultrafast speeds',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco4,
                                title : 'Time to switch',
                                text : 'Talk to our dedicated Switch Squad today',
                            },
                        ],
                        image: {
                            mediaItemUrl:img1
                        },
                        // vimeoId: '755043986',
                        vimeoId: '755135590',
                    },
                    // {
                    //     id: 'q-ent',
                    //     text : '<h1>Go 25x faster with Sky Broadband Gigafast</h1><p>We’re rolling out game-changing speeds, 25x faster than our standard fibre​</p>',
                    //     blocks : [
                    //         {
                    //             bg: '#F2F2F2',
                    //             img : imgBlocco6,
                    //             title : 'Game-changing speed',
                    //             text : 'With an average download speed of 900Mb/s to the hub​',
                    //         },
                    //         {
                    //             bg: '#F2F2F2',
                    //             img : imgBlocco3,
                    //             title : 'Connect over 100 devices',
                    //             text : 'Stream in UHD, game online in 4k and video call all at same time',
                    //         },
                    //         {
                    //             bg: '#F2F2F2',
                    //             img : imgBlocco5,
                    //             title : 'Ultra reliable full fibre broadband',
                    //             text : 'Full fibre is the UK’s most reliable broadband technology​',
                    //         },
                    //     ],
                    //     image: {
                    //         mediaItemUrl:img2
                    //     },
                    // },
                    {
                        id: 'q-ent3',
                        text : '',
                        blocks : [],
                        image: {
                            mediaItemUrl:img4
                        },
                    },
                    {
                        id: 'q-hd',
                        // text : '<h1>Switching is simple</h1><p>Remote switching is available in most cases without an engineer. Our Switch Squad will keep you connected to your current provider right up until your switch.</h6>',
                        text : '<h1>Get set up with Sky</h1>',
                        blocks : [
                            {
                                bg: '#F2F2F2',
                                title : 'Everything is easy with Sky',
                                text : 'Everything’s easy with Sky. First, choose your broadband speed. Then choose a date for activation or installation (we may need to come round). We’ll pop your new hub in the post.',
                            },
                            {
                                bg: '#F2F2F2',
                                title : 'Switching from another provider?',
                                text : 'We’ll contact them for you, or we’ll let you know if you need to contact them. Also if you’re switching from another provider, we’ll text when your broadband’s ready.',
                            },
                            {
                                bg: '#F2F2F2',
                                title : 'Booked an engineer visit?',
                                text : 'We’ll call a day before to confirm your appointment. Our engineer will wear a face-covering to help keep everyone safe.',
                            },
                        ],
                        image: {
                            mediaItemUrl:img3
                        },
                        vimeoId: '755135590',
                    },
                ]
            }
        },

    }
}

export default q;